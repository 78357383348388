* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Kalam', cursive;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  line-height: 1;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

input,
select,
option,
optgroup,
textarea,
button,
pre,
code {
  font-size: 100%;
  font-family: inherit;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #1e2839;
}

a:hover {
  text-decoration: #1e2839 underline wavy;
  color: #1e2839;
}
