/* Parallax  */
.prlx1,
.prlx2,
.prlx3 {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.prlx1 {
    background-image: url('../assets/flowerMeadow.jpg');
    min-height: 100%;
    height: 100vh;
}

.prlx2 {
    background-image: url('../assets/blueFlowers.jpg');
    height: 40vh;
}

.prlx3 {
    background-image: url('../assets/whiteFlowers.jpg');
    height: 40vh;
}

.caption {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    text-align: center;
}

.name {
    background-color: rgba(30, 40, 57, 0.75);
    color: #e3e4e6;
    padding: 18px;
    font-size: 20px;
    letter-spacing: 15px;
    border-radius: 10px;
}

.projects {
    font-size: 35px;
    color: #e3e4e6;
    letter-spacing: 10px;
}

.contact {
    font-size: 35px;
    color: #1e2839;
    letter-spacing: 10px;
}

/* Head and foot */
.footer {
    text-align: center;
    background-color: transparent;
}

.header {
    padding: 5px;
    font-size: 15px;
}

.navLinks {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Content */
.content {
    background-color: e3e4e6;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    color: 1e2839;
}

hr {
    border: 1px dashed rgba(10, 43, 100, 0.15);
    width: 50%;
    margin: auto;
}

/* Bio section */
.pic {
    border-radius: 30%;
    width: 80%;
}

.flex,
.pic1 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    order: -1
}

.mainBio,
.graduation,
.dogs,
.bike {
    width: 100%;
    margin: auto;
}

.desc,
.favorites {
    width: 95%;
}

.favorites {
    margin: auto;
}

.button {
    border: none;
    background-color: transparent;
}

.button:hover {
    border-bottom: 1px solid black;
}

/* Projects */
.projectContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.item {
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.item > a:hover {
    text-decoration: none;
    opacity: 35%;
}

.projectImg {
    width: 100%;
}

/* Contact */
.iconList {
    font-size: 50px;
    display: flex;
    justify-content: space-between;
}

.skillList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}

@media only screen and (max-device-width: 500px) {

    .prlx1,
    .prlx2,
    .prlx3 {
        background-attachment: scroll;
    }
}

@media screen and (min-width: 500px) {
    .name {
        font-size: 30px;
    }

    .content,
    .item {
        font-size: 25px;
    }

    .flex,
    .pic1 {
        flex-direction: row;
        order: 0
    }

    .prlx2,
    .prlx3 {
        min-height: 250px;
    }

    .projectContainer {
        flex-direction: row;
        padding: 10px 10%;
    }

    .imgContainer {
        flex: 1
    }

    .header {
        padding: 10px;
        font-size: 20px;
    }

    .navLinks {
        justify-content: space-around;
    }

    .projectImg {
        max-height: 180px;
        object-fit: cover;
    }

    .item {
        flex: 2;
    }

    .imgContainer,
    .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .iconList {
        justify-content: space-around;
    }

    .skillList {
        width: 35%;
        margin: auto;
    }
}

@media screen and (min-width: 1030px) {

    .mainBio,
    .graduation,
    .dogs,
    .bike,
    .projectContainer {
        padding: 10px 15%;
    }

    hr {
        border: none;

    }

}
